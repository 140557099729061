<section id="top-of-page">
  <app-privacy-header></app-privacy-header>
</section>

<div class="container">
  <div class="row py-sm-5">
    <div class="col-sm-4 me-sm-2 me-md-5">
      <app-privacy-menu active="cookies"></app-privacy-menu>
    </div>
    <div class="col me-md-5 pt-4 pt-sm-0">
      <h1>Cookie policy</h1>
      <p>Safened uses functional, analytical, and marketing cookies. Functional and analytical cookies are used to ensure your visit to our websites goes smoothly. By contrast marketing cookies are not necessary for the proper functioning of our websites but are used for promotional purposes. For more information about the specific cookies read the subsection in our Cookie Policy about what types of cookies we use.</p>

      <h2>What are Cookies?</h2>
      <p>Many of the websites you visit place small, encrypted text files on your device (smartphone, computer, or tablet). We call these text files cookies. Safened uses cookies or similar techniques whenever you interact with our website. A cookie is sent by a web server to a web browser that enables the server to collect information back from the browser creating connectivity between a company and the customer. In other words, the cookies on the websites (and some e-mails) store small amounts of information on your device to allow browser information from your web browser to be collected. This information could be used to make it easier for you to use the website or for marketing purposes. Cookies generally process your IP-address, but they do not save your personal information such as your e-mail address or phone number (only if you give us the permission to do this). Above all, cookies allow websites to monitor website visitor’s behavior.</p>
      <p>You can find more information on the website <a href="https://www.allaboutcookies.org/" target="_blank" rel="noreferrer noopener">All about cookies</a>.</p>
      
      <h2>What types of cookies do we use?</h2>
      <p>These cookies may store your browser name, the type of computer and technical information about your means of connection to our website, such as the operating system and the Internet Service Providers utilized and other similar information. Safened uses this information to technically facilitate the visitors of this website. In addition, functional cookies may be used to store personal settings, such as language or to log your information for next visits to save you the trouble of having to change every time you enter our website. To conclude, our website could not work properly without these cookies.</p>
      
      <h3>1. Functional/Preference cookies</h3>
      <p>These cookies may store your browser name, the type of computer and technical information about your means of connection to our website, such as the operating system and the Internet Service Providers utilized and other similar information. Safened uses this information to technically facilitate the visitors of this website. In addition, functional cookies may be used to store personal settings, such as language or to log your information for next visits to save you the trouble of having to change every time you enter our website. To conclude, our website could not work properly without these cookies.</p>

      <h3>2. Analytical cookies</h3>
      <p>Safened uses cookies to help us understand and improve the customer experience on our website. We use Google Analytics, a web analysis service, to generate statistical and other analytical information to learn about visitor preferences and provide them with the right information more quickly. The goal is to provide much greater functionality to visitors. Therefore, we also examine whether visitors experience any forms of errors or inaccuracies on the website. We use the analytical cookies also to test different designs and features for our sites. All information is collected in a pseudonymized or anonymized form.</p>

      <h3>3. Marketing cookies</h3>
      <p>This website contains cookies from third-party websites, like lead generation and social media cookies. When placed on your computer, they automatically track the session visitors of companies spent on the site or activate handy extras, for example, to share content information of Safened on their LinkedIn. These cookies inform our website whether you are logged into such social media and they also make it easy for you to share pages on social media or to display social content about a topic. If you would like to know more about how these third-party platforms use data, please refer to the privacy notices of these platforms. When visiting this website, Safened will ask for your consent to use these cookies. In other words, we only place marketing cookies if you gave us your permission to do this.</p>

      <h2>Manage cookies</h2>
      <p>To see what cookies have been set and how to manage, block and/or delete them, please read this subsection on how you object to cookies. If you do not want to have cookies stored on your computer or want to remove cookies that have already been stored, you can change settings, at any time on this website by clicking the change cookie settings on the cookie button on the homepage. Another option is to arrange the settings (privacy & security) in your web browser. This so-called “Do Not Track” is a function that allows visitors not to be tracked by websites.</p>
      <p>
        Do Not Track options are available in several browsers including:<br>
        <a href="https://support.google.com/chrome/answer/114836?hl=en&ref_topic=3421433" target="_blank" rel="noreferrer noopener">Chrome</a><br>
        <a href="https://support.mozilla.org/en-US/kb/how-do-i-turn-do-not-track-feature" target="_blank" rel="noreferrer noopener">Firefox</a><br>
        <a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank" rel="noreferrer noopener">Edge</a><br>
        <a href="http://help.apple.com/safari/mac/9.0/#/sfri40732" target="_blank" rel="noreferrer noopener">Safari</a><br>
        <a href="https://help.opera.com/en/latest/security-and-privacy/#tracking" target="_blank" rel="noreferrer noopener">Opera</a><br>
      </p>

      <p>The advantage of this method is that consumers are less likely to be confronted with cookie banners (a pop-up that appears when a visitor comes to a website), which improves your browsing experience.</p>

      <div #cookieBotWrapper></div>

    </div>
  </div>
</div>

<app-scroll-to-top-button [routerLink]="['/cookies']" fragment="top-of-page"></app-scroll-to-top-button>
