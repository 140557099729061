<!-- Close button -->
<button class="close" disableRipple="true" mat-button (click)="close()">&times;</button>
<h3>Request a demo or information</h3>

<mat-dialog-content>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <!-- Full name -->
    <div class="form-group">
      <label>
        full name
        <input class="form-control" type="text" formControlName="fullName">
      </label>
      <div *ngIf="fullName.invalid && (fullName.dirty || fullName.touched)">
        <div class="form-error-text" *ngIf="fullName.errors.required">
          full name is required
        </div>
      </div>
    </div>

    <!-- Company name -->
    <div class="form-group">
      <label>
        company name
        <input class="form-control" type="text" formControlName="companyName">
      </label>
      <div *ngIf="companyName.invalid && (companyName.dirty || companyName.touched)">
        <div class="form-error-text" *ngIf="companyName.errors.required">
          company name is required
        </div>
      </div>
    </div>

    <!-- Email -->
    <div class="form-group">
      <label>
        email address
        <input class="form-control" type="email" formControlName="email">
      </label>
      <div *ngIf="email.invalid && (email.dirty || email.touched)">
        <div class="form-error-text" *ngIf="email.errors.required">
          email address is required
        </div>
        <div class="form-error-text" *ngIf="email.errors.email">
          invalid email address
        </div>
      </div>
    </div>

    <!-- Interested in -->
    <div class="form-group">
      <label>
        interested in solutions for
        <select #interestedInSelect class="form-select" formControlName="interestedIn" (change)="onSelectedOptionChanged()">
          <option hidden disabled value="0">select an option...</option>
          <option *ngFor="let option of interestedInOptions" [ngValue]="option.value">
            {{ option.name }}
          </option>
        </select>
      </label>
      <div *ngIf="interestedIn.invalid && (interestedIn.dirty || interestedIn.touched)">
        <div class="form-error-text" *ngIf="interestedIn.errors.min">
          Interested in is required
        </div>
      </div>
    </div>

    <input formControlName="honeypot" class="hidden" type="text" />

    <!-- Submit -->
    <div class="d-flex justify-content-center">
      <app-chip-button backgroundColor="primary" [minWidth]="'220px'" [noBorder]="true">
        <ng-container *ngIf="!isFormSubmitting">Send</ng-container>
        <mat-spinner *ngIf="isFormSubmitting" color="white" diameter="34"></mat-spinner>
      </app-chip-button>
    </div>

  </form>
</mat-dialog-content>