<!-- Page header -->
<div class="header" [ngClass]="{'sticky-header': shouldShowStickyHeader()}">
  <app-header></app-header>
</div>
<!--/. Page header -->

<!-- content -->
<app-hero></app-hero>
<app-our-solutions></app-our-solutions>
<app-why-safened></app-why-safened>
<app-about-us></app-about-us>
<app-carfang-quote-component></app-carfang-quote-component>
<app-learn-more></app-learn-more>
<app-contact-us></app-contact-us>
<!--/. content -->
