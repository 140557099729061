import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InterestedModalContentComponent } from './content/interested-modal-content.component';

@Component({
  selector: 'app-interested-modal',
  templateUrl: './interested-modal.component.html',
  styleUrls: ['./interested-modal.component.scss']
})
export class InterestedModalComponent {

   constructor(private modalService: MatDialog) { }

   /**
    * Open the modal
    */
   public openDialog(): void {
     this.modalService.open(InterestedModalContentComponent, {
       data: {},
       panelClass: 'form-dialog',
       backdropClass: 'dialog-backdrop',
       autoFocus: false,
       restoreFocus: false
     });
   }
}
