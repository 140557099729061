import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-privacy-menu',
  templateUrl: './privacy-menu.component.html',
  styleUrls: ['./privacy-menu.component.scss']
})
export class PrivacyMenuComponent {

  @Input() public active: string = "";
}
