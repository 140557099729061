<section id="top-of-page">
  <app-privacy-header></app-privacy-header>
</section>

<div class="container">
  <div class="row py-sm-5">
    <div class="col-sm-4 me-sm-2 me-md-5">
      <app-privacy-menu active="bcp"></app-privacy-menu>
    </div>
    <div class="col me-md-5 pt-4 pt-sm-0">
      <h1>Safened US, Inc.</h1>
      <h2>Business Continuity Planning</h2>
      <p>Safened US, Inc., hereinafter Safened, has developed a Business Continuity Plan to respond to events that may significantly disrupt our business. Since the timing and impact of disasters and disruptions is unpredictable, we will be flexible in responding to actual events as they occur. With that in mind, we provide you with this information on our business continuity plan.</p>

      <h2>Contacting Safened US</h2>
      <p>If, after a significant business disruption you cannot contact us as you usually do, you should contact us at +1 646-522-8432.</p>
  
      <h2>Our Business Continuity Plan</h2>
      <p>We plan to quickly recover and resume business operations after a significant business disruption and respond by safeguarding our employees and property, making a financial and operational assessment, protecting the firm's books and records, and retrieving key customer records. In short, our business continuity plan is designed to permit our firm to resume operations as quickly as possible, given the scope and severity of the significant business disruption.</p>
      <p>Our business continuity plan addresses:  data backup and recovery; financial and operational assessments; alternative communications with customers, employees, and regulators; alternate physical location of employees; critical service provider, bank, and customer impact; and regulatory reporting.</p>
      <p>We have retained third party service providers to ensure remote backup facilities and off-site storage for our books and records. While every emergency situation poses unique problems based on external factors, such as time of day and the severity of the disruption, we expect to be able to promptly retrieve our books and records. We will quickly establish alternative arrangements if a critical service provider can no longer provide the needed goods or services when we need them because of a Significant Business Disruption to them or our firm.</p>

      <h2>Varying Disruptions</h2>
      <p>Significant Business Disruptions can vary in their scope, such as only our firm, a single building housing our firm, the business district where our firm is located, the city where we are located, or the whole region.  Within each of these areas, the severity of the disruption can also vary from minimal to severe.  In a disruption to only our firm or a building housing our firm, we will transfer our operations to a local site when needed and expect to recover and resume business within 48 hours. In a disruption affecting our business district, city, or region, we will transfer our operations to a site outside of the affected area and recover and resume business as soon as possible. In either situation we plan to continue in business and notify you how to contact us. If the significant business disruption is so severe that it prevents us from remaining in business, we will discuss alternative arrangements with you.</p>

      <h2>For more information</h2>
      <p>If you have questions about our business continuity planning, you can contact us at +1 646-522-8432.</p>

    </div>
  </div>
</div>

<app-scroll-to-top-button [routerLink]="['/bcp']" fragment="top-of-page"></app-scroll-to-top-button>
