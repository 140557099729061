<section id="our-solutions">
  <div class="container pb-7">
  <h2 class="pt-3">Our Solutions</h2>

  <div class="description">
  <h4>Seamlessly connecting high quality global banks with corporate clients</h4>
  </div>

  <div class="row">
    <div class="col-md">
      <img src="assets/images/hex-corporate.svg" alt="corporate">
      <h3>Corporate Clients</h3>
      <p>Use our innovative, diversified suite of deposit products to optimize your liquidity. Take advantage of our state-of-the-art platform to achieve safety, diversity, and potentially a higher yield.</p>
    </div>
    <div class="col-md">
      <img src="assets/images/hex-bank.svg" alt="banks">
      <h3>Partner Banks</h3>
      <p>Get access to a cost-effective, Basel-III friendly wholesale funding alternative so that you can efficiently attract USD deposits. US based banks can now source balance sheet friendly, non-brokered deposits via our solution.</p>
    </div>
    <div class="col-md">
      <img src="assets/images/hex-agents.svg" alt="agents">
      <h3>Distribution Partners</h3>
      <p>Offer your clients a flexible, white-label asset/liability solution that seamlessly connects to front-end order entry and back-end reporting systems while the client manages its deposit composition.</p>
    </div>
  </div>
</div>
</section>