<section id="top-of-page">
  <app-privacy-header></app-privacy-header>
</section>

<div class="container">
  <div class="row py-sm-5">
    <div class="col-sm-4 me-sm-2 me-md-5">
      <app-privacy-menu active="disclosures"></app-privacy-menu>
    </div>
    <div class="col me-md-5 pt-4 pt-sm-0">
      <h1>Important disclosures</h1>

      <h3>1. About the important disclosures</h3>
      <p>These are the important disclosures of Safened US, Inc., hereinafter Safened, a company incorporated in the United States, with its registered office located at 104 W 40th St, Office 432, New York, 10018, United States. Safened US, Inc. is registered with the US Securities and Exchange Commission and is a member of FINRA and SIPC.</p>
      <p>Safened treats data which it receives through its websites and any other means with due care and is dedicated to safeguarding any data it receives. These disclosures are designed to inform you about the type of information that Safened collects when using our website and the purposes for which this information is being processed, used, maintained, and disclosed (together hereinafter Services).</p>
      <p>We aim to explain in a simple and transparent way what we gather and how we process it. It applies to the following:</p>
      <ul>
        <li>the legal representatives and ultimate beneficial owners of all past, present, and prospective commercial contracting parties;</li>
        <li>all representatives of a business that are interested in entering into an agreement with Safened;</li>
        <li>anyone visiting the Safened website.</li>
      </ul>

      <h3>2. Your Data</h3>
      <p>With your data we refer to any information that tells us something about you or that we can link to you. Safened processes any information we receive from you, including when you or your business: enquire or make an application for Safened services, register to use and/or use any of our services and when you communicate with us. Such information may include your: Full name including first name and family name, company name, e-mail address, etc.</p>

      <h3>3. Sensitive data</h3>
      <p>We do not record sensitive data relating to a someone’s health, ethnicity, religious or political beliefs unless it is strictly necessary. When we do it is limited to specific circumstances.</p>

      <h3>4. What we do with your data</h3>
      <p>We only use your data for legitimate business reasons. This includes:</p>
      <ul>
        <li><strong>Administration</strong><br>When you provide us with information by filling in a form on our website or through the application, we are legally obliged to collect data that verifies your identity and to assess whether we can accept you or your company as a customer.</li><br>
        <li><strong>Managing customer relationships</strong><br>We may ask you for feedback about our products and services and share this with certain members of our staff to improve our offering.</li><br>
        <li><strong>Providing you with the best-suited products and services</strong><br>When you visit our website, we gather information about you. We analyze this information to identify your potential needs and assess the suitability of products or services. We assess your needs in relation to key moments when a specific product or service may be relevant for you.</li><br>
        <li><strong>Improving and developing products and services</strong><br>Analyzing how you use our products and services helps us understand more about you and shows us where we can improve. For instance, we analyze the results of our marketing activities to measure their effectiveness and the relevance of our campaigns.</li><br>
        <li><strong>Preventing and detecting fraud and data security</strong><br>We have a duty to protect your data and to prevent, detect and contain data breaches. This includes information we are obliged to collect about you, for example to comply with regulations against money laundering, terrorism financing and tax fraud.</li><br>
        <li><strong>Internal and external reporting</strong><br>We process your data for our operations and to help our management make better decisions about our operations and services. To comply with a range of legal obligations and statutory requirements (anti-money laundering legislation and tax legislation, for example).</li><br>
      </ul>

      <h3>5. Who we share your data with and why</h3>
      <p>Whenever we share data internally or with third parties in other countries, we ensure the necessary safeguards are in place to protect it.</p>
      <p>To be able to offer you the best possible services and remain competitive in our business, we share certain data both internally as well as outside of Safened. This includes:</p>

      <h4>Safened entities</h4>
      <p>We transfer data across Safened businesses and branches for operational, regulatory, or reporting purposes, for example to comply with certain laws, secure IT systems or provide certain services. We may also transfer data to centralized storage systems or to process it globally for more efficiency.</p>

      <h4>Government authorities</h4>
      <p>To comply with our regulatory obligations, we may disclose data to the relevant authorities, for example to counter terrorism and prevent money laundering. In some cases, we are obliged by law to share the data with external parties, including:</p>
      <ul>
        <li>public authorities, regulators and supervisory bodies;</li>
        <li>judicial/investigative authorities such as the police, public prosecutors, courts, and arbitration/mediation bodies on their express and legal request; and</li>
        <li>lawyers, for example, in case of a claim or bankruptcy, trustees who take care of the interest of other parties and company auditors.</li>
      </ul>

      <h4>Third party service providers</h4>
      <p>When we use other service providers we only share data that is required for the particular task we involve the service provider for. Service providers support us with activities like:</p>
      <ul>
        <li>performing certain services and operations;</li>
        <li>designing and maintenance of internet-based tools and applications;</li>
        <li>marketing activities or events and managing customer communications;</li>
        <li>preparing reports and statistics, printing materials and designing products; and</li>
        <li>placing advertisements on apps, websites and social media.</li>
      </ul>

      <h4>Business transfers</h4>
      <p>Safened may buy or sell business units or affiliates. In such circumstances, we may transfer customer information as a business asset. Without limiting the foregoing, if our business enters a joint venture with or is sold to or merged with another business entity, your information may be disclosed to our new business partners or owners.</p>

      <h4>With your permission</h4>
      <p>Your information may also be used for other purposes for which you give your specific permission, or when required by law or where permitted under the terms of the laws of the relevant jurisdiction.</p>

      <h3>6. Cookie Policy</h3>
      <p>Safened makes use of cookies and similar technologies throughout our websites to ensure your visit to our website goes smoothly. Our websites use cookies and other technologies, which store small amounts of information on your computer or device, to allow certain information from your web browser to be collected. Cookies (and similar technologies) are widely used on the internet and allow a website to recognize a users device, without uniquely identifying the individual person using the computer. These technologies help to make it easier for you to log on and use our websites and provide information to us, for example which parts of the website you visit. For more information about cookies, including how to see what cookies have been set and how to manage, block and/or delete them, please refer to the below information about our Cookie Policy.</p>

      <h3>7. Your duty to provide data</h3>
      <p>There is certain information that we must know about you so that we can commence and execute our duties as a payment institution and fulfil our associated obligations. There is also information that we are legally obliged to collect. Without this data we may for example not be able to enter into an agreement with you.</p>

      <h3>8. How we protect your data</h3>
      <p>We apply an internal framework of policies and minimum standards to keep your data safe. These policies and standards are periodically updated to keep them up to date with regulations and market developments. More specifically and in accordance with the law, we take appropriate technical and organizational measures (policies and procedures, IT security etc.) to ensure the confidentiality and integrity of your data and the way it is processed.</p>
      <p>In addition, Safened employees are subject to confidentiality and may not disclose your data unlawfully or unnecessarily.</p>

      <h3>9. What you can do to help us keep your data safe</h3>
      <p>Unfortunately, the transmission of information via the internet in general is not always completely secure. Although we will do our best to protect your information, we cannot guarantee the security of your information transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access. We do our utmost to protect your data, but there are certain things you can do too:</p>
      <ul>
        <li>install anti-virus software, anti-spyware software and a firewall on your computer and keep them updated;</li>
        <li>do not leave verification tokens unattended;</li>
        <li>keep your passwords strictly confidential and use strong passwords, i.e. avoid obvious combinations of letters and figures; and</li>
        <li>be alert online and learn how to spot unusual activity, such as a new website address or phishing emails requesting personal information.</li>
      </ul>

      <h3>10. How long we keep your data</h3>
      <p>Safened will store and process your data only as long as it is necessary to perform our obligations under the agreement with you or as long as the law requires to store it. Therefore, we keep your data as long as you are using our Services. There may be circumstances (e.g. fraud or anti-money laundering) whereby we are obliged to store your data even longer.</p>

      <h3>11. Risk disclosures</h3>
      <p>Prospective purchasers should understand the risks associated with their investment and should only reach an investment decision after careful consideration and in consultation with their legal, tax, accounting and other advisers, of the suitability of the investment in the light of their own particular financial, tax and other circumstances, and the information set out in the offering document(s).</p>
      <p>If an investment is made in any asset or issued by a party subject to foreign laws or transactions made on markets in other jurisdictions, including markets formally linked to a domestic market, recovery of the sums invested and any profits or gains may be reduced, delayed or prevented by exchange controls, debt moratorium or other actions imposed by the government or other official bodies.</p>
      <p>THIS IS FOR INFORMATIONAL PURPOSES ONLY AND DOES NOT CONSTITUTE AN OFFER TO SELL OR A SOLICITATION OF AN OFFER TO BUY SECURITIES.</p>
      <p>Past performance does not guarantee future results.</p>
      <p><strong>For Institutional USE Only</strong></p>

      <h3>12. Contact us</h3>
      <p>If you want to know more about the data policies of Safened and how we use your data, you can send us an e-mail at the following dedicated e-mail address: <strong>dpo@safened.com</strong></p>

    </div>
  </div>
</div>

<app-scroll-to-top-button [routerLink]="['/disclosures']" fragment="top-of-page"></app-scroll-to-top-button>