import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsModalContentComponent } from './content/contact-us-modal-content.component';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss']
})
export class ContactUsModalComponent {

  constructor(private modalService: MatDialog) { }

  /**
   * Open the modal
   */
  public openDialog(): void {
    this.modalService.open(ContactUsModalContentComponent, {
      data: {},
      panelClass: 'form-dialog',
      backdropClass: 'dialog-backdrop',
      autoFocus: false,
      restoreFocus: false
    });
  }
}
