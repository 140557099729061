<section id="about-us">
  <div class="container py-7">
    <h2>About us</h2>

    <div class="description">
      <h4>We are committed to helping banks and corporate clients solve today's primary liquidity challenges</h4>
    </div>

    <div class="row">
      <!-- <div class="col-md">
        <img src="assets/images/photo-placeholder.png" alt="photo1" class="round-photo">
        <h3>Chris van Straeten</h3>
        <div class="mat-caption">Group Chief Executive Officer</div>
        <p>Over 25 years experience in finance, risk, audit and compliance at European based banks, private equity funds
          and audit firms. Before becoming Group CEO, Chris was CFRO and co-Founder of Safened-Fourthline.</p>
      </div> -->
      <!-- <div class="col-md">
        <img src="assets/images/headshot-hans-peter.jpg" alt="photo2" class="round-photo">
        <h3>Hans-Peter Gruenig</h3>
        <div class="mat-caption">Chief Executive Officer Safened US</div>
        <p>Hans-Peter has more than 20 years of experience leading US units of mid-size European investment
          banks and brokerages such Vontobel, Pictet and Lombard Odier. As part owner of European financial services, he
          led the establishment and expansion of the US activities.</p>
      </div> -->
      <div class="col-md">
        <img src="assets/images/headshot-graeme.jpg" alt="photo3" class="round-photo">
        <h3>Graeme Henderson</h3>
        <div class="mat-caption">Head of Sales</div>
        <p>Graeme joined Safened with nearly 25 years of experience in FinTech and financial institutions,
          having previously worked for Cachematrix/Blackrock, responsible for their 3rd party money market funds
          platform offering. Before that he worked at LaSalle Bank and Bank of America.</p>
      </div>
    </div>
    <div class="d-flex justify-content-center pt-7">
      <p>Safened US, Inc. and Safened Deposits B.V. are subsidiary companies of Safened Limited.</p>
    </div>
  </div>
</section>