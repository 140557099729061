<section id="why-safened">
  <div class="wrapper">
    
    <div class="container py-7">
      <h2>Why Safened</h2>

      <div class="description">
        <h4 class="pb-3">We optimize liquidity for corporate clients and effectively help banks source their deposit
          funding</h4>
      </div>

      <!-- Commented out untill we have the final asset(s) -->
      <app-video url="https://www.youtube.com/embed/SvDDXgcHYyA?autoplay=1&mute=0" thumbnail="assets/images/placeholders/video-thumbnail.jpg"
        description="Why Safened explained (approx. 2min.)"></app-video>

      <div class="row pt-7">
        <div class="col-md">
          <span class="header-number">1</span><span class="header-dot">.</span>
          <h3>Simple, yet comprehensive</h3>
          <p>Our innovative deposit product suite and optimization tools are ideal, whether you are a corporate
            treasurer managing a short-term portfolio, or a banker sourcing deposits. Our growing network of corporate
            and Institutional investors, combined with our partner banks, puts the entire market at your command.</p>
        </div>
        <div class="col-md">
          <span class="header-number">2</span><span class="header-dot">.</span>
          <h3>Singular focus</h3>
          <p>You have our full attention. Our liquidity platform is our only business. We are independent – free to
            offer you the very best solutions without bias or preference. We are not beholden to any financial
            institution or corporate investor.</p>
        </div>
        <div class="col-md">
          <span class="header-number">3</span><span class="header-dot">.</span>
          <h3>Highest level of confidence</h3>
          <p>As a US Broker/Dealer, our proprietary platform facilitates end-to-end execution and control in a secure and regulatory environment, including KYC and AML processes.</p>
        </div>
      </div>
    </div>

    <img class="background-image" src="/assets/images/why-safened-background.jpg">

  </div>
</section>