import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { OurSolutionsComponent } from './our-solutions/our-solutions.component';
import { WhySafenedComponent } from './why-safened/why-safened.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CarfangQuoteComponent } from './carfang-quote/carfang-quote.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { HeroComponent } from './hero/hero.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([])
  ],
  declarations: [
    HeaderComponent,
    OurSolutionsComponent,
    WhySafenedComponent,
    AboutUsComponent,
    CarfangQuoteComponent,
    ContactUsComponent,
    LearnMoreComponent,
    FooterComponent,
    HeroComponent,
  ],
  exports: [
    OurSolutionsComponent,
    WhySafenedComponent,
    AboutUsComponent,
    CarfangQuoteComponent,
    LearnMoreComponent,
    FooterComponent,
    ContactUsComponent,
    RouterModule,
    HeroComponent,
    HeaderComponent
  ],
})
export class CoreModule { }
