import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { MainMenuButtonComponent } from './components/buttons/main-menu-button/main-menu-button.component';
import { ChipButtonComponent } from './components/buttons/chip-button/chip-button.component';
import { QuoteComponent } from './components/quote/quote.component';
import { InterestedModalComponent } from './components/modals/interested-modal/interested-modal.component';
import { InterestedModalContentComponent } from './components/modals/interested-modal/content/interested-modal-content.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactUsModalComponent } from './components/modals/contact-us-modal/contact-us-modal.component';
import { ContactUsModalContentComponent } from './components/modals/contact-us-modal/content/contact-us-modal-content.component';
import { PrivacyHeaderComponent } from './components/privacy-header/privacy-header.component';
import { PrivacyMenuComponent } from './components/privacy-menu/privacy-menu.component';
import { RouterModule } from '@angular/router';
import { ScrollToTopButtonComponent } from './components/buttons/scroll-to-top-button/scroll-to-top-button.component';
import { SuccessModalContentComponent } from './components/modals/success-modal/content/success-modal-content.component';
import { VideoComponent } from './components/video/video.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule.forChild([])
  ],
  declarations: [
    MainMenuButtonComponent, 
    ChipButtonComponent, 
    QuoteComponent, 
    InterestedModalComponent, 
    InterestedModalContentComponent, 
    VideoComponent, 
    ContactUsModalComponent,
    ContactUsModalContentComponent,
    PrivacyHeaderComponent,
    PrivacyMenuComponent,
    ScrollToTopButtonComponent,
    SuccessModalContentComponent
  ],
  exports: [
    MaterialModule,
    MainMenuButtonComponent,
    ChipButtonComponent,
    QuoteComponent,
    InterestedModalComponent, 
    InterestedModalContentComponent,
    VideoComponent, 
    ContactUsModalComponent,
    PrivacyHeaderComponent,
    PrivacyMenuComponent,
    ScrollToTopButtonComponent
  ]
})
export class SharedModule { }
