import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GoogleSpreadsheetAndMailApiService } from 'src/app/core/services/http/google-spreadsheet-and-mail/google-spreadsheet-and-mail-api.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { SuccessModalContentComponent } from '../../success-modal/content/success-modal-content.component';

@Component({
  selector: 'app-interested-modal-content',
  templateUrl: './interested-modal-content.component.html',
  styleUrls: ['./interested-modal-content.component.scss']
})
export class InterestedModalContentComponent {

  // The form with all the controls
  public form: FormGroup;

  // Getters for form fields
  get fullName() { return this.form.get('fullName'); }
  get companyName() { return this.form.get('companyName'); }
  get email() { return this.form.get('email'); }
  get honeypot() { return this.form.get('honeypot'); }

  constructor(private apiService: GoogleSpreadsheetAndMailApiService, private formBuilder: FormBuilder, private modalService: MatDialog, public dialogRef: MatDialogRef<InterestedModalContentComponent>) {
    // Create form
    this.form = this.formBuilder.group({
      fullName: [''],
      companyName: [''],
      email: ['', [Validators.required, CustomValidators.email]],
      honeypot: [''],
    });
  }

  /**
   * Sumbit the form
   */
  public onSubmit(): void {
    // Trigger form validation for all controls
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control!.markAsTouched({ onlySelf: true });
    });

    if (this.form.valid) {
      this.sendForm();
      this.downloadWhitepaper();
      this.openSuccessDialog();
      this.close();
    }
  }
  
  /**
   * Download the whitepaper
   */
  public downloadWhitepaper(): void {
      // Create and click download link
      const link = document.createElement('a');
      link.download = 'Deposit Bulletin September 2021 TCG Safened';
      link.href = "assets/docs/whitepaper.pdf";
      link.click();
  }
  
  /**
   * Close the dialog
   */
  public close(): void {
    this.dialogRef.close();
  }

  /**
   * Show the success modal to inform the user the form was submitted successfully
   */
  public openSuccessDialog(): void {
    this.modalService.open(SuccessModalContentComponent, {
      data: 'Your download has started...',
      panelClass: 'form-dialog',
      backdropClass: 'dialog-backdrop',
      autoFocus: false,
      restoreFocus: false
    });
  }

    /**
   * Send the form data to the api
   */
  private sendForm(): void {
    if (!this.form.valid || this.honeypot.value) return;

    // Create formdata to send`
    const formData = new FormData();
    formData.append('Full name', this.fullName.value);
    formData.append('Company name',  this.companyName.value);
    formData.append('Email', this.email.value);

    formData.append('formDataNameOrder', '{"1": "Full name", "2": "Company name", "3": "Email"}');

    // Post to api
    this.apiService.sendInterestedInForm(formData).subscribe(
      (response: any) => {
        if (response['result'] !== 'success') {
          console.log('Failed to send form: ' + response.error);
        } 
      },
      (error) => {
        console.log('Failed to send form: ' + error);
      }
    );
  }
}
