<div class="container">
  <div class="row">
    <div class="col-md my-1">
      Safened US, Inc. is registered with the US SEC and is a member of FINRA and SIPC.
    </div>
    <div class="col-md-auto align-self-end me-sm-5 my-1">
      <a [routerLink]="['/licenses']">Licenses</a>&nbsp;&nbsp;-&nbsp;&nbsp;<a [routerLink]="['/disclosures']">Disclosures</a>&nbsp;&nbsp;-&nbsp;&nbsp;<a [routerLink]="['/cookies']">Cookies</a>&nbsp;&nbsp;-&nbsp;&nbsp;<a [routerLink]="['/bcp']">Bcp</a>
    </div>
    <div class="col-md-auto align-self-end my-1">
      Copyright &copy;{{year}} – Safened All rights reserved
    </div>
  </div>
</div>