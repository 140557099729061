<!-- Close button -->
<button class="close" disableRipple="true" mat-button (click)="close()">&times;</button>
<h3>Who is interested?</h3>

<mat-dialog-content>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <!-- Full name -->
    <div class="form-group">
      <label>
        full name
        <input class="form-control" type="text" formControlName="fullName">
      </label>
    </div>

    <!-- Company name -->
    <div class="form-group">
      <label>
        company name
        <input class="form-control" type="text" formControlName="companyName">
      </label>
    </div>

    <!-- Email -->
    <div class="form-group">
      <label>
        email address
        <input class="form-control" type="email" formControlName="email">
      </label>
      <div *ngIf="email.invalid && (email.dirty || email.touched)">
        <div class="form-error-text" *ngIf="email.errors.required">
          email address is required
        </div>
        <div class="form-error-text" *ngIf="email.errors.email">
          invalid email address
        </div>
      </div>
    </div>

    <input formControlName="honeypot" class="hidden" type="text" />

    <!-- Submit -->
    <div class="d-flex justify-content-center">
      <app-chip-button backgroundColor="primary" [noBorder]="true">Download</app-chip-button>
    </div>

  </form>
</mat-dialog-content>