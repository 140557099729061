<div class="container py-6">
  <div class="row">
    <div class="col-lg">
      <div class="logo"><img src="assets/images/logo.svg" alt="logo"></div>
    </div>
    <div class="col-lg col-md-6">
      <span class="mat-body-strong">New York</span>
      <p>
        Safened US, Inc.<br>
        104 West 40th Street, Office 432<br>
        10011 New York, NY<br>
        United States<br>
      </p>
    </div>
    <div class="col-lg col-md-6">
      <span class="mat-body-strong">Amsterdam</span>
      <p>
        Safened Deposits B.V.<br>
        Tesselschadestraat 12<br>
        1054 ET Amsterdam<br>
        The Netherlands<br>
      </p>
    </div>
    <div class="col-lg col-md-6">
      <span class="mat-body-strong">London</span>
      <p>
        Safened Limited<br>
        107 Cheapside<br>
        EC2V 6DN, London<br>
        United Kingdom<br>
      </p>
    </div>
    <div class="col-lg-auto col-md-6">
      <span class="mat-body-strong">Contact us</span>
      <div class="contact-us">
        <img src="assets/images/icon-mail.svg" class="icon"><a href="mailto:contact@safened.com">contact@safened.com</a><br>
        <img src="assets/images/icon-linkedin.svg" class="icon"><a href="https://www.linkedin.com/company/safened-us/" target="_blank" rel="noreferrer noopener">LinkedIn</a>
      </div>
    </div>
  </div>
</div>