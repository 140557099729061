<div class="py-md-3 ms-md-5">
  <mat-divider class="d-none d-sm-block"></mat-divider>
  <a [routerLink]="['/licenses']" [ngClass]="{'active': active === 'licenses'}">Licenses</a>
  <mat-divider></mat-divider>
  <a [routerLink]="['/disclosures']" [ngClass]="{'active': active === 'disclosures'}">Disclosures</a>
  <mat-divider></mat-divider>
  <a [routerLink]="['/cookies']" [ngClass]="{'active': active === 'cookies'}">Cookies</a>
  <mat-divider></mat-divider>
  <a [routerLink]="['/bcp']" [ngClass]="{'active': active === 'bcp'}">Business Continuity Plan</a>
  <mat-divider></mat-divider>
</div>