<div id="page-container">

  <div id="content">
    <router-outlet></router-outlet>
  </div>
  
  <!-- page footer -->
  <div id="footer">
    <app-footer></app-footer>
  </div>
  <!--/. page footer -->

</div>
