import { FormControl, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

/**
 * Custom form control validators
 */
export class CustomValidators {

  /**
   * Returns an error when the form control's value does not meet the specified regex for email
   */
  public static email(control: FormControl) {
    if (control.value !== null && typeof control.value === 'string' && control.value) {
      // Regex source: https://emailregex.com/
      const regex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!regex.test(control.value)) {
        return {
          email: true
        };
      }
    }
    return null;
  };
}
