import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { HamburgerService } from '../services/hamburger.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  // Handle to hamburger menu
  @ViewChild('hamburgerSideNavigation') sideNavigation: ElementRef | undefined;

  // True when the header is shown as a sticky menu
  public isSticky: boolean = false;

  constructor(public hamburgerService: HamburgerService) {}

  /**
   * Called when the hamburger menu is shown or hidden
   */
  public toggleHamburgerMenu(): void {
    this.hamburgerService.toggleHamburgerMenuActive();

    if (this.hamburgerService.isHamburgerMenuActive) {
      // Show side navigation
      this.sideNavigation!.nativeElement.style.right = "0";
    }
    else {
      this.sideNavigation!.nativeElement.style.right = "-100%";
    }
  }

  /**
   * Called whenever the screen is resized
   * @param event The resize event
   */
  @HostListener("window:resize", ['$event'])
  private onResize(event: any) {
    if(this.hamburgerService.isHamburgerMenuActive)
    {
      if(event.target.innerWidth > 768){
        // Hide the hamburger menu when the screen is big enough to show the normal menu
        this.toggleHamburgerMenu();
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  public onScroll(event: any): void {
    this.isSticky = window.pageYOffset > 90;
  }

}
