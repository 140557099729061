import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HamburgerService {

  // True when the hamburger menu is shown
  private _isHamburgerMenuActive: boolean = false;
  
  get isHamburgerMenuActive() { return this._isHamburgerMenuActive }

  constructor() { }

  public toggleHamburgerMenuActive() {
    this._isHamburgerMenuActive = !this._isHamburgerMenuActive;
  }
}
