import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-header',
  templateUrl: './privacy-header.component.html',
  styleUrls: ['./privacy-header.component.scss']
})
export class PrivacyHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
