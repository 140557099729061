<div class="container-fluid">
  <div class="row">
    <div class="col col-background-pattern-left d-none d-sm-none d-md-block">
      <img src="assets/images/comp-pattern.svg" class="background-pattern-left">
    </div>
    <div class="col-lg-5 col-md-6 learn-more py-6">
      <h2>Want to learn more?</h2>
      <p>Get in touch with us for a demo or more information.</p>
      <app-contact-us-modal></app-contact-us-modal>
    </div>
    <div class="col col-background-pattern-right d-none d-sm-none d-md-block">
      <img src="assets/images/comp-pattern.svg" class="background-pattern-right">
    </div>
  </div>
</div>