import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BcpComponent } from './bcp/bcp.component';
import { CookiesComponent } from './cookies/cookies.component';
import { DisclosuresComponent } from './disclosures/disclosures.component';
import { HomeComponent } from './home/home.component';
import { LicensesComponent } from './licenses/licenses.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'licenses', component: LicensesComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: 'disclosures', component: DisclosuresComponent },
  { path: 'bcp', component: BcpComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
