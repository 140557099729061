<div class="container-fluid">
  <div class="row hero-wrapper-row">
    <div class="col-xl-auto col-md-6 col hero-wrapper-col py-5 pe-lg-5">
      
      <h1 class="title pt-md-4 pe-lg-5">The Global Liquidity Platform</h1>
      <p class="me-3">
        Raising USD deposit funding for banks and offering clients a diversified way to easily manage their short-term liquidity.
      </p>

      <app-chip-button [routerLink]="['/']" fragment="our-solutions" [minWidth]="'180px'">Explore more</app-chip-button>
    </div>

    <!-- screenshot -->
    <div class="col-sm-3 col-4 d-none d-sm-none d-md-block ps-lg-5">
      <div class="hero-screenshot-wrapper ms-xl-5">
        <div class="hero-screenshot"></div>
      </div>
        <!-- <img src="assets/images/screenshot.jpg" alt="screenshot" class="hero-screenshot"> -->
        <!-- <img src="assets/images/screenshot.jpg" alt="screenshot" class="hero-screenshot"> -->
    </div>

  </div>
</div>

<!-- white diagonal mask at bottom -->
<div class="mask d-none d-sm-none d-md-block"></div>
