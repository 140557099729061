import { Component, HostListener, OnInit } from '@angular/core';
import { HamburgerService } from 'src/app/core/services/hamburger.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // Current page y offset
  public pageYOffset: number = 0;

  // True when the last scroll was to the top of the page
  public scrollingToTop: boolean = false;

  // Sticky header breakpoint information
  public showStickyHeaderBreakpoint: number = 768;
  public showStickyHeader: boolean = false;

  constructor(private hamburgerService: HamburgerService) { }

  ngOnInit(): void {
    // Initialize depending on screen size
    this.showStickyHeader = window.innerWidth <= this.showStickyHeaderBreakpoint;
  }

  @HostListener('window:scroll', ['$event'])
  public onScroll(event: any): void {
    if (this.pageYOffset > window.pageYOffset) {
      // We are scrolling to the top of the page
      this.scrollingToTop = true;
    }
    else {
      this.scrollingToTop = false;
    }

    this.pageYOffset = window.pageYOffset;
  }

  /**
   * Called whenever the screen is resized
   * @param event The resize event
   */
  @HostListener("window:resize", ['$event'])
  private onResize(event: any): void {
    this.showStickyHeader = event.target.innerWidth <= this.showStickyHeaderBreakpoint;
  }

  /**
   * Returns true when the sticky header should be shown
   */
  public shouldShowStickyHeader(): boolean {
    return this.pageYOffset > 0 && (this.scrollingToTop || this.hamburgerService.isHamburgerMenuActive) && this.showStickyHeader;
  }
}
