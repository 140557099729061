<iframe *ngIf="isVideoShown" class="embedded-video" [src]="safeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

<a *ngIf="!isVideoShown" class="embedded-video-thumbnail" (click)="playVideo()" [style.background-image]="'url(' + thumbnail + ')'">
  <div class="darken-layer"></div>
  
  <div class="video-information">
    <div class="play-button" [style.background-image]="'url(assets/images/play-button-white.svg)'"></div>
    <div class="info">{{ description }}</div>
  </div>
</a>
