import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GoogleSpreadsheetAndMailApiService } from 'src/app/core/services/http/google-spreadsheet-and-mail/google-spreadsheet-and-mail-api.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { SuccessModalContentComponent } from '../../success-modal/content/success-modal-content.component';

@Component({
  selector: 'app-contact-us-modal-content',
  templateUrl: './contact-us-modal-content.component.html',
  styleUrls: ['./contact-us-modal-content.component.scss']
})
export class ContactUsModalContentComponent {

  // The form with all the controls
  public form: FormGroup;
  public isFormSubmitting: boolean;

  // The options shown in the 'interested in' select form control 
  public interestedInOptions = [
    { name: 'Corporate Clients', value: 1 },
    { name: 'Partner Banks', value: 2 },
    { name: 'Distribution Partners', value: 3 },
    { name: 'Other', value: 4 }
  ];

  // Reference to select element
  @ViewChild('interestedInSelect') public interestedInSelect: ElementRef | undefined;

  // Getters for form fields
  get fullName() { return this.form.get('fullName'); }
  get companyName() { return this.form.get('companyName'); }
  get email() { return this.form.get('email'); }
  get interestedIn() { return this.form.get('interestedIn'); }
  get honeypot() { return this.form.get('honeypot'); }

  constructor(private apiService: GoogleSpreadsheetAndMailApiService, private formBuilder: FormBuilder, private modalService: MatDialog, public dialogRef: MatDialogRef<ContactUsModalContentComponent>) {
    // Create form
    this.form = this.formBuilder.group({
      fullName: ['', Validators.required],
      companyName: ['', Validators.required],
      email: ['', [Validators.required, CustomValidators.email]],
      interestedIn: [0, [Validators.min(1)]],
      honeypot: [''],
    });
  }

  /**
   * Sumbit the form
   */
  public onSubmit(): void {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control!.markAsTouched({ onlySelf: true });
    });

    // Try to send the form
    this.sendForm();
  }

  /**
   * Called whenever the option in the select element changes
   */
  public onSelectedOptionChanged(): void {
    if (this.interestedIn!.value == 0) {
      // 'Disabled' option is selected
      this.interestedInSelect!.nativeElement.style.color = "rgba(0,0,0,0.5)";
    }
    else {
      this.interestedInSelect!.nativeElement.style.color = "rgba(0,0,0,1.0)";
    }
  }

  /**
   * Close the dialog
   */
  public close(): void {
    this.dialogRef.close();
  }

  /**
   * Show the success modal to inform the user the form was submitted successfully
   */
  public openSuccessDialog(): void {
    this.modalService.open(SuccessModalContentComponent, {
      panelClass: 'form-dialog',
      backdropClass: 'dialog-backdrop',
      autoFocus: false,
      restoreFocus: false
    });
  }

  /**
   * Send the form data to the api
   */
  private sendForm(): void {
    if (!this.form.valid || this.honeypot.value || this.isFormSubmitting) return;

    this.isFormSubmitting = true;

    // Create formdata to send
    const formData = new FormData();
    formData.append("Full name", this.fullName.value);
    formData.append("Company name",  this.companyName.value);
    formData.append("Email", this.email.value);
    formData.append("Interested in", this.getInterestedInName(this.interestedIn.value));

    formData.append('formDataNameOrder', '{"1": "Full name", "2": "Company name", "3": "Email", "4": "Interested in"}');

    // Post to api
    this.apiService.sendContactUsForm(formData).subscribe(
      (response: any) => {
        if (response["result"] == "success") {
          // Form was succesfully send
          this.openSuccessDialog()
          this.close();
        } 
        else {
          console.log('Failed to send form: ' + response.error);
        }

        this.isFormSubmitting = false;
      },
      (error) => {
        console.log('Failed to send form: ' + error);
        this.isFormSubmitting = false;
      }
    );
  }

  /**
   * Returns the name that corresponds the specified interested in value 
   * @param interestedInValue The intersted in value to get the name for
   */
  private getInterestedInName(interestedInValue: number): string {
    let interestedInName = 'unkown';
    this.interestedInOptions.forEach(interestedInOption => {
      if(interestedInOption.value == interestedInValue) {
        interestedInName = interestedInOption.name;
      }
    });

    return interestedInName;
  }
}
