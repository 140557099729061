import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  @Input() public url: string = "";
  @Input() public thumbnail: string = "";
  @Input() public description: string = "";

  // Trusted url that can be used to play the video
  public safeUrl: SafeResourceUrl;

  // True when the thumbnail was clicked and the video is showing */
  public isVideoShown: boolean = false;

  constructor(private sanitizer: DomSanitizer){
  }

  public ngOnInit(): void {
    // Trust this resource so we can use it in our html
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  /**
   * Start playing the video 
   */
  public playVideo(): void {
    this.isVideoShown = true;
  }
}
