import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';
import { LicensesComponent } from './licenses/licenses.component';
import { HomeComponent } from './home/home.component';
import { CoreModule } from '../core/core.module';
import { CookiesComponent } from './cookies/cookies.component';
import { DisclosuresComponent } from './disclosures/disclosures.component';
import { BcpComponent } from './bcp/bcp.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    PagesRoutingModule
  ],
  declarations: [
    LicensesComponent,
    HomeComponent,
    CookiesComponent,
    DisclosuresComponent,
    BcpComponent
  ]
})
export class PagesModule { }
