import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-modal-content',
  templateUrl: './success-modal-content.component.html',
  styleUrls: ['./success-modal-content.component.scss']
})
export class SuccessModalContentComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, public dialogRef: MatDialogRef<SuccessModalContentComponent>) { }

  /**
   * Close the dialog
   */
   public close(): void {
    this.dialogRef.close();
  }
}
