<section id="top-of-page">
  <app-privacy-header></app-privacy-header>
</section>

<div class="container">
  <div class="row py-sm-5">
    <div class="col-sm-4 me-sm-2 me-md-5">
      <app-privacy-menu active="licenses"></app-privacy-menu>
    </div>
    <div class="col me-md-5 pt-4 pt-sm-0">
      <h1>Our licenses</h1>

      <p>FINRA</p>
      <ul>
        <li>For membership information we refer to the following page: <a href="https://brokercheck.finra.org/firm/summary/308433" target="_blank" rel="noreferrer noopener">FINRA</a></li>
      </ul> 

      <p>SIPC</p>
      <ul>
        <li>For membership information we refer to the following page: <a href="https://www.sipc.org/list-of-members/?query=Safened" target="_blank" rel="noreferrer noopener">SIPC</a></li>
      </ul> 

    </div>
  </div>
</div>

<app-scroll-to-top-button [routerLink]="['/licenses']" fragment="top-of-page"></app-scroll-to-top-button>
