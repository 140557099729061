import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements AfterViewInit {

  // Handle to div where to place cookiebot script
  @ViewChild('cookieBotWrapper') cookieBotWrapper: ElementRef | undefined;
    
  public ngAfterViewInit(): void {
    this.loadCookieDeclarationScript();
  }

  /**
   * Load a the cookie bot declaration script dynamically via ts.
   * Angular doesnt allow scripts on component level
   */
  private loadCookieDeclarationScript():void {
    const body = <HTMLDivElement> document.body;
    let node = document.createElement('script');
    node.id = 'CookieDeclaration';
    node.src = 'https://consent.cookiebot.com/17c3c983-44e6-4fb1-9d6f-717a7d7caed6/cd.js';
    node.type = 'text/javascript';
    node.async = false;
    this.cookieBotWrapper.nativeElement.appendChild(node);
  }
}
