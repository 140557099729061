<div class="container">
  
  <div class="row">
    <!-- logo -->
    <div class="col my-auto"><a [routerLink]="['/']"><img src="assets/images/logo-white.svg" alt="logo" class="logo"></a></div>

    <!-- main menu -->
    <div class="col-auto align-self-end my-auto pe-0">
      <nav class="d-none d-sm-none d-md-block">
        <app-main-menu-button [routerLink]="['/']" fragment="our-solutions">Our Solutions</app-main-menu-button>
        <app-main-menu-button [routerLink]="['/']" fragment="why-safened">Why Safened</app-main-menu-button>
        <app-main-menu-button [routerLink]="['/']" fragment="about-us">About Us</app-main-menu-button>
        <a href="https://careers.stablr.com" target="_blank" rel="noreferrer noopener"><app-main-menu-button>Careers</app-main-menu-button></a>
      </nav>

      <nav class="d-block d-sm-block d-md-none">
        <!-- hamburger -->
        <button class="hamburger hamburger--collapse" [ngClass]="{'is-active': this.hamburgerService.isHamburgerMenuActive}" type="button" (click)="toggleHamburgerMenu()">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </nav>
      
    </div>
    <!--/. main menu -->
  </div>

  <mat-divider *ngIf="!isSticky"></mat-divider>
</div>

<!-- hamburger side menu -->
<div #hamburgerSideNavigation class="hamburger-navigation">
  <div class="container py-5">
    <a [routerLink]="['/']" fragment="our-solutions" class="py-3" (click)="toggleHamburgerMenu()">Our Solutions<img src="assets/images/icon-chevron-right.svg" class="hamburger-menu-link"></a>
    <a [routerLink]="['/']" fragment="why-safened" class="py-3" (click)="toggleHamburgerMenu()">Why Safened<img src="assets/images/icon-chevron-right.svg" class="hamburger-menu-link"></a>
    <a [routerLink]="['/']" fragment="about-us" class="py-3" (click)="toggleHamburgerMenu()">About Us<img src="assets/images/icon-chevron-right.svg" class="hamburger-menu-link"></a>
    <a [routerLink]="['/']" class="py-3" (click)="toggleHamburgerMenu()">Careers<img src="assets/images/icon-chevron-right.svg" class="hamburger-menu-link"></a>
  </div>
</div>
