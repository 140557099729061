import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * This class performs api calls to a google spreadsheet file that stores the form data in the spreadsheet and sends a mail
 * to the 'mailTo' address
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleSpreadsheetAndMailApiService {

  /* Api url to make requests to web api */
  public apiUrl: string = 'https://script.google.com/macros/s/AKfycbx0bF-jO9tfLMltC4wYSL9wxYXxfOlgroSalq4OzdilnFzSMkgdI6_Ob6Wivn01njE4/exec';
  public mailTo: string = 'contact@safened.com'

  constructor(private http: HttpClient) { }

  public sendContactUsForm(formData: FormData) {
    // The name of the sheet the data will be stored in
    formData.append('formGoogleSheetName', 'Request demo or information');

    // The email address this information will be send to (in addition to storing it in the sheet)
    formData.append('formGoogleSendEmail', this.mailTo);

    return this.http.post(this.apiUrl, formData);
  }

  public sendInterestedInForm(formData: FormData) {
    // The name of the sheet the data will be stored in
    formData.append('formGoogleSheetName', 'Download whitepaper');

    // The email address this information will be send to (in addition to storing it in the sheet)
    formData.append('formGoogleSendEmail', this.mailTo);

    return this.http.post(this.apiUrl, formData);
  }
}
