<!-- Close (x) button -->
<button class="close" disableRipple="true" mat-button (click)="close()">&times;</button>

<h3>Thank you</h3>

<mat-dialog-content>

  <!-- dynamic text -->
  <h4 *ngIf="dialogData; else elseBlock">
    {{ dialogData }}
  </h4>

  <!-- default text -->
  <ng-template #elseBlock>
    <h4>
      Your request has been sent.<br class="d-none d-sm-none d-md-block">
      We'll be in touch shortly...
    </h4>
  </ng-template>

  <!-- Close button -->
  <div class="d-flex justify-content-center">
    <app-chip-button (click)="close()" backgroundColor="primary" [noBorder]="true">Close</app-chip-button>
  </div>

</mat-dialog-content>