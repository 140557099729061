<section id="carfang-quote">
  <div class="container py-3">

    <div class="row">
      <div class="col-md my-auto py-5">
        <div class="quote-wrapper">
          <app-quote></app-quote>
        </div>

        <div class="w-100">
          <app-interested-modal></app-interested-modal>
        </div>
      </div>

      <div class="col-md-auto d-none d-sm-none d-md-none d-lg-block text-end">
        <div class="carfang-hex-logo">
          <img src="assets/images/hex-big.svg" alt="hexagon carfang" class="carfang-hex">
          <img src="assets/images/the-carfang-group-logo.svg" alt="logo carfang" class="carfang-logo">
        </div>
      </div>

    </div>
  </div>
</section>